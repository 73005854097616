<script lang="ts" setup>
import { computed } from "vue";

const breadcrumbs = computed(() => {
  return [{ default: "EU-Base" }];
});
</script>

<template>
  <v-container fluid pa-0 class="app-iam">
    <inn-breadcrumbs :items="breadcrumbs"></inn-breadcrumbs>

    <v-container fluid>
      <header class="app-welcome-message">
        <h2>Energiedata beheer</h2>
      </header>
      <v-row>
        <v-col cols="2" class="sidebar">
          <v-list nav>
            <v-list-item-group>
              <v-list-item text :to="{ name: 'EU-Registers' }">
                <v-list-item-icon><v-icon>mdi-speedometer</v-icon></v-list-item-icon>
                <v-list-item-title>Meters</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <router-view></router-view>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
